import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: 8 Rounds of Tabata Hollow Rocks.`}</p>
    <p>{`Then, 8 Rounds of :10 on, :10 off doing 1 tuck up — 1 pike up — 1
scissor then hollow hold until :10 mark.`}</p>
    <p>{`Then,`}</p>
    <p>{`200ft Versa Climber, 100ft Bear Crawl`}</p>
    <p>{`500M Ski Erg, 100ft Bear Crawl`}</p>
    <p>{`1000M Row, 100ft Bear Crawl`}</p>
    <p>{`2 Lap Run, 100ft Bear Crawl`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual Thanksgiving Day wod will be at 9:00am this Thursday!
 This workout is free to all so bring a friend!  This will be the only
class of the day. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Friday we will reopen for the 10:30 class.  We will be back to
our normal schedule for the rest of the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      